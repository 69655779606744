import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { getStats, formatNumber } from '../../../utils/stats'

const Container = styled.div`
  background: #76f935;
  padding: 30px 10px;
  margin-top: 30px;
  ${tw`
    xl:hidden
  `}
`

const List = styled.ul`
  list-style: none;
  ${tw`
    m-0
    p-0
    flex
    flex-col
    w-full
  `}
`

const Item = styled.li`
  ${tw`
    block
    text-black
  `}
  padding: 10px 5px;
  padding-bottom: 0;

  &:not(:last-child) {
    border-bottom: 2px solid white;
    padding-bottom: 10px;
  }
`

const Value = styled.div`
  font-size: 35px;
  font-family: 'Matter SQ Mono TRIAL Semi Bold', sans-serif;
`

const Description = styled.div`
  font-size: 15px;
`

function HomepageMobileStats() {
  const [stats, setStats] = useState(null)

  useEffect(() => {
    async function loadStats() {
      await getStats().then((data) => {
        setStats(data)
      })
    }

    const interval = setInterval(() => {
      loadStats()
    }, 5000)

    loadStats()
    return () => clearInterval(interval)
  }, [])

  return stats ? (
    <Container>
      <List>
        {stats.latest_block_time_in_seconds && (
          <Item>
            <Description>Block Time</Description>
            <Value>{formatNumber(stats.latest_block_time_in_seconds)}</Value>
          </Item>
        )}
        {stats.avg_transaction_count_per_second_for_last_7_days && (
          <Item>
            <Description>Current Activity (TPS)</Description>
            <Value>
              {formatNumber(
                stats.avg_transaction_count_per_second_for_last_7_days,
                true
              )}{' '}
            </Value>
          </Item>
        )}
        {stats.total_created_address_count && (
          <Item>
            <Description>Addresses</Description>
            <Value>{formatNumber(stats.total_created_address_count)}</Value>
          </Item>
        )}
        {stats.avg_transaction_fee_for_yesterday && (
          <Item>
            <Description>Transaction Fee</Description>
            <Value>
              {`$${parseFloat(stats.avg_transaction_fee_for_yesterday).toFixed(
                4
              )}`}
            </Value>
          </Item>
        )}
      </List>
    </Container>
  ) : null
}

export default HomepageMobileStats
