import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import tw from 'tailwind.macro'
import { screen } from '../../../utils/index'
import { getStats, formatNumber } from '../../../utils/stats'

const Container = styled.div`
  font-family: 'Inter', sans-serif;
  min-height: 330px;
  ${tw`
    relative
    w-full
    text-white
    flex
    flex-col
    justify-between
  `}
  ${screen('xl')} {
    min-height: calc(80vh - 90px);
  }
`

const Stats = styled.ul`
  list-style-type: none;
  max-width: 1360px;

  ${screen('xl')} {
    ${tw`
      flex
    `}
  }

  ${tw`
    hidden
    m-0
    p-0
    w-full
    relative
    justify-between
    items-start
  `}
  padding: 18px 40px;
  margin: 0 auto;
`

const StatsItem = styled.li``

const StatsItemTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 24px;
  font-family: 'Matter SQ Mono TRIAL Regular', sans-serif;

  ${tw`
    font-normal
    uppercase
  `}
`

const StatsItemValue = styled.p`
  font-size: 60px;
  line-height: 54px;
  letter-spacing: -0.03em;
  font-family: 'Matter SQ Mono TRIAL Regular', sans-serif;
`

const BackgroundVideo = styled.video`
  object-fit: cover;
  min-height: 330px;
  ${tw`
    w-full
  `}
  ${screen('xl')} {
    ${tw`
      absolute
      h-full
  `}
  }
`

const MarqueeContainer = styled.div`
  padding-bottom: 5px;
  font-size: 22px;
  bottom: 0;
  left: 0;
  right: 0;

  ${screen('xl')} {
    font-size: 30px;
  }

  ${tw`
    absolute
    text-white
    mt-0
  `}
`

const MarqueeSeparator = styled.span`
  margin: 0 10px;
`

const TextBlock = styled.div`
  color: white;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 30px;

  ${screen('md')} {
    max-width: calc(75% + 40px);
    font-size: 25px;
    line-height: 35px;
    margin: 0 auto;
    margin-top: 40px;
  }
`

const TextSection = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  margin-bottom: 20px;

  ${screen('md')} {
    padding: 0 40px;
  }
`

function FutureOfFinance({ marquee, video, textBelowOne, textBelowTwo }) {
  const [stats, setStats] = useState(null)

  useEffect(() => {
    async function loadStats() {
      await getStats().then((data) => {
        setStats(data)
      })
    }

    const interval = setInterval(() => {
      loadStats()
    }, 5000)

    loadStats()
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Container>
        <BackgroundVideo src={video.url} autoPlay playsInline muted loop />
        {stats && (
          <Stats>
            {stats.latest_block_time_in_seconds && (
              <StatsItem>
                <StatsItemTitle>BLOCK TIME</StatsItemTitle>
                <StatsItemValue>
                  {formatNumber(stats.latest_block_time_in_seconds)}
                </StatsItemValue>
              </StatsItem>
            )}
            {stats.avg_transaction_count_per_second_for_last_7_days && (
              <StatsItem>
                <StatsItemTitle>CURRENT ACTIVITY (TPS)</StatsItemTitle>
                <StatsItemValue>
                  {formatNumber(
                    stats.avg_transaction_count_per_second_for_last_7_days,
                    true
                  )}{' '}
                </StatsItemValue>
              </StatsItem>
            )}
            {stats.total_created_address_count && (
              <StatsItem>
                <StatsItemTitle>ADDRESSES</StatsItemTitle>
                <StatsItemValue>
                  {formatNumber(stats.total_created_address_count)}
                </StatsItemValue>
              </StatsItem>
            )}
            {stats.avg_transaction_fee_for_yesterday && (
              <StatsItem>
                <StatsItemTitle>TRANSACTION FEE</StatsItemTitle>
                <StatsItemValue>
                  {`$${parseFloat(
                    stats.avg_transaction_fee_for_yesterday
                  ).toFixed(4)}`}
                </StatsItemValue>
              </StatsItem>
            )}
          </Stats>
        )}
        {marquee && marquee.length && (
          <MarqueeContainer>
            <Marquee loop={0} pauseOnHover gradient={false}>
              {[...marquee, ...marquee].map((item, index) => (
                <React.Fragment>
                  <span key={`item_${index}`}>{item}</span>
                  {index !== marquee.length - 1 ? (
                    <MarqueeSeparator>/</MarqueeSeparator>
                  ) : null}
                </React.Fragment>
              ))}
            </Marquee>
          </MarqueeContainer>
        )}
      </Container>
      <TextSection>
        <TextBlock style={{ color: '#76f935' }}>{textBelowOne}</TextBlock>
        <TextBlock>{textBelowTwo}</TextBlock>
      </TextSection>
    </>
  )
}

export default FutureOfFinance
