async function getStats() {
  try {
    const response = await fetch(
      'https://new-metrics.algorand.org/api/algorand-statistics/',
      {
        cache: 'no-cache',
        redirect: 'follow',
      }
    )

    const json = await response.json()

    return json
  } catch (e) {
    console.log('Error fetching stats', e)
    return {
      latest_block_time_in_seconds: 4.379666666666667,
      avg_block_time_for_last_7_days: 4.351266961163791,
      avg_transaction_count_per_second_for_last_7_days: 12.59480324074074,
      max_transaction_count_per_second_for_last_7_days: 1108,
      total_address_count: 11963938,
      total_created_address_count: 23855477,
      total_repo_count_within_go_algorand: 1,
      total_contributor_count_within_go_algorand: 66,
      total_forker_count_within_go_algorand: 303,
      total_stargazer_count_within_go_algorand: 994,
      total_repo_count_across_algorand_public_repos: 104,
      total_contributor_count_across_algorand_public_repos: 756,
      total_forker_count_across_algorand_public_repos: 878,
      total_stargazer_count_across_algorand_public_repos: 1819,
      total_repo_count_across_all_github: 1104,
      total_contributor_count_across_all_github: 2893,
      total_forker_count_across_all_github: 6343,
      total_stargazer_count_across_all_github: 7503,
      avg_transaction_fee_for_yesterday: 0.0012278192,
    }
  }
}

function formatNumber(value, removeDecimals = false) {
  let result = value

  if (result) {
    if (removeDecimals) {
      result = new Intl.NumberFormat('en-US', {}).format(
        Math.round(parseInt(value) * 100) / 100
      )
    } else {
      result = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
      })
        .format(Math.round(value * 100) / 100)
        .replace(/\D00(?=\D*$)/, '')
    }
  }

  return result
}

export { formatNumber, getStats }
