import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import { screen } from '../../../utils/index'
import ArrowRight from '../../Shapes/ArrowRight'
import SmartLink from '../SmartLink'

const Container = styled.div`
  font-family: 'Inter', sans-serif;
  ${tw`
    relative
  `}
`

const ContainerInner = styled.div`
  padding: 0;
  padding-top: 45px;
  max-width: 1360px;
  padding-bottom: 50px;

  ${screen('xl')} {
    padding-bottom: 215px;
    padding-left: 48px;
    padding-right: 48px;
  }

  ${tw`
    mx-auto
    my-0
  `}
`

const Button = styled.div`
  height: 70px;
  border: 1px solid #76f935;
  letter-spacing: -0.03em;
  color: #76f935;
  padding: 0 22px;
  font-size: 22px;
  line-height: 24px;

  ${screen('xl')} {
    font-size: 24px;
    line-height: 26px;
  }

  &:hover {
    background: #76f935;
  }

  ${tw`
    inline-flex
    font-semibold
    items-center
    justify-center
    cursor-pointer
    w-full

    hover:text-white
  `}

  ${screen('xl')} {
    ${tw`
      w-auto
    `}
  }
`

const TopContainer = styled.div`
  ${tw`
    flex
    relative
    flex-col  
  `}
  ${screen('xl')} {
    ${tw`
      flex-row
    `}
  }
`

const CenterContainer = styled.div`
  ${tw`
    flex
    relative
    flex-col-reverse
  `}
  padding-top: 50px;
  padding-bottom: 50px;

  ${screen('xl')} {
    padding-top: 150px;
    padding-bottom: 65px;
    ${tw`
      flex-row
    `}
  }
`

const BackgroundVideo = styled.video`
  object-fit: cover;

  ${tw`
    xl:absolute
    xl:h-full
    w-full
  `}
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.05em;

  ${screen('xl')} {
    font-size: 64px;
    line-height: 55.5px;
  }
`

const Description = styled.p`
  letter-spacing: -0.03em;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;

  ${screen('xl')} {
    font-size: 24px;
    line-height: 28px;
  }
`

const Subtitle = styled.h3`
  letter-spacing: -0.03em;
  font-size: 26px;
  line-height: 29px;

  ${tw`
    uppercase
    font-semibold
    pt-10
  `}

  ${screen('xl')} {
    font-size: 36px;
    line-height: 34px;
    padding-top: 0;
  }
`

const ProjectIconContainer = styled.div`
  display: inline-flex;
  height: 160px;
  width: 160px;
  background: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px white;
  transform-style: preserve-3d;
  transition: transform 0.8s;
`

const ProjectIcon = styled.img`
  max-width: 110px;
  object-fit: scale-down;
  max-height: 110px;
  min-height: 110px;
  min-width: 110px;
  backface-visibility: hidden;
  position: absolute;
`

const ProjectIconColor = styled.img`
  max-width: 110px;
  object-fit: scale-down;
  max-height: 110px;
  min-height: 110px;
  min-width: 110px;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  position: absolute;
`

const BottomContainer = styled.div`
  ${tw`
    flex
    relative
    items-center
    flex-row
    flex-wrap
  `}
  ${screen('xl')} {
    ${tw`
      justify-between
    `}
  }
`

const Project = styled.div`
  &:first-of-type {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
  }
  width: 50%;
  text-align: center;

  ${screen('xl')} {
    margin: 0 14px;
    width: unset;
    text-align: unset;
  }

  &:hover {
    a div {
      transform: rotateY(180deg);
    }
    a p {
      color: #76f935;
    }
  }
`

const ProjectName = styled.p`
  transition: color ease 0.8s;
  margin-top: 28px;
  ${tw`
    text-center
    pb-8
    xl:pb-0
  `}
`

function EcosystemRecentProjects({
  title,
  description,
  background,
  subtitle,
  ctaText,
  ctaLink,
  projects,
}) {
  return (
    <Container>
      {background && (
        <BackgroundVideo src={background} autoPlay playsInline loop muted />
      )}
      <ContainerInner>
        <TopContainer>
          <div className="w-full xl:w-1/2 text-white">
            <Title>{title}</Title>
          </div>
          <div className="w-full xl:w-1/2 text-white">
            <Description>{description}</Description>
          </div>
        </TopContainer>
        <CenterContainer>
          <div className="w-full xl:w-1/2 text-white">
            <Subtitle>{subtitle}</Subtitle>
          </div>
          <div className="w-full xl:w-1/2 text-white text-right">
            <SmartLink to={ctaLink} className="w-full lg:w-1/3 no-underline">
              <Button>
                <span style={{ marginRight: 13 }}>{ctaText}</span>
                <ArrowRight />
              </Button>
            </SmartLink>
          </div>
        </CenterContainer>
        <BottomContainer>
          {projects && projects.length
            ? projects.map((item, index) => (
                <Project key={`project_${index}`}>
                  <SmartLink to={item.link}>
                    <ProjectIconContainer style={{ backgroundColor: item.bg }}>
                      <ProjectIcon src={item.icon} />
                      <ProjectIconColor src={item.iconColor || item.icon} />
                    </ProjectIconContainer>
                    <ProjectName className="text-white">
                      {item.name}
                    </ProjectName>
                  </SmartLink>
                </Project>
              ))
            : null}
        </BottomContainer>
      </ContainerInner>
    </Container>
  )
}

export default EcosystemRecentProjects
