import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import '../../../../static/homepagev4/fonts.css'
import EcosystemRecentProjects from '../../General/EcosystemRecentProjects'
import FutureOfFinance from '../../General/FutureOfFinance'
import HomepageTiles from '../../General/HomepageTiles'
import HomepageMobileStats from '../../General/HomepageMobileStats/HomepageMobileStats'
import '../../../../static/fonts/fonts.css'

// assets

const backgrounds = {
  desktop: null,
  mobile: null,
  mobile2x: null,
}

const HomeContainer = styled.div`
  ${tw`
    bg-black
    bg-center
    bg-cover
`}
`

const renderComponent = (component) => {
  const { primary, items } = component

  switch (component.slice_type) {
    case 'hero': {
      return (
        <>
          <FutureOfFinance
            marquee={
              items &&
              items.map((item) => {
                return item.marquee_item[0].text
              })
            }
            video={primary.video}
            textBelowOne={
              primary.text_below_hero && primary.text_below_hero[0]
                ? primary.text_below_hero[0].text
                : null
            }
            textBelowTwo={
              primary.text_below_hero_2 && primary.text_below_hero_2[0]
                ? primary.text_below_hero_2[0].text
                : null
            }
          />
          <HomepageMobileStats />
        </>
      )
    }
    case 'ecosystem': {
      return (
        <EcosystemRecentProjects
          title={primary.title && primary.title[0] && primary.title[0].text}
          description={
            primary.description &&
            primary.description[0] &&
            primary.description[0].text
          }
          subtitle={
            primary.subtitle && primary.subtitle[0] && primary.subtitle[0].text
          }
          ctaText={
            primary.cta_text && primary.cta_text[0] && primary.cta_text[0].text
          }
          projects={
            items &&
            items.length &&
            items.map((project) => {
              return {
                name: project.name && project.name[0] && project.name[0].text,
                icon: project.icon && project.icon.url,
                iconColor: project.icon_color && project.icon_color.url,
                link:
                  project.project_link &&
                  project.project_link[0] &&
                  project.project_link[0].text,
                bg: project.background_color || 'white',
              }
            })
          }
          background={primary.video && primary.video.url}
          ctaLink={
            primary.cta_link && primary.cta_link[0] && primary.cta_link[0].text
          }
        />
      )
    }
    case 'tiles': {
      return (
        <HomepageTiles
          title_line_one={
            primary.title_line_1 &&
            primary.title_line_1[0] &&
            primary.title_line_1[0].text
          }
          title_line_two={
            primary.title_line_2 &&
            primary.title_line_2[0] &&
            primary.title_line_2[0].text
          }
          tiles={
            items &&
            items.map((item) => ({
              background_color: item.background_color,
              description:
                item.description &&
                item.description[0] &&
                item.description[0].text,
              description_and_source_text_color:
                item.description_and_source_text_color,
              image: item.image && item.image.url,
              title:
                item.tile_title &&
                item.tile_title[0] &&
                item.tile_title[0].text,
              source: item.source && item.source[0] && item.source[0].text,
              title_color: item.title_color,
              link: item.link && item.link[0] && item.link[0].text,
              big: item.big,
              fullImageBackground: !!item.use_image_as_full_background,
              fullImageTransparentBg: !!item.use_transparent_background_color,
            }))
          }
        />
      )
    }

    default:
      return null
  }
}

const HomepageV5 = ({ seo, data }) => {
  if (!data || !data.body) return null
  const components = data.body

  return (
    <PageWrap
      seo={seo}
      backgrounds={backgrounds}
      bg={false}
      darkMode
      disableVerticalPadding
    >
      <HomeContainer className="-mb-12 lg:-mb-32 pb-8 md:pb-10 text-white">
        {components && components.length > 0
          ? components.map((el, index) => {
              const component = renderComponent(el)
              if (!component) return null
              return <div key={`component_${index}`}>{component}</div>
            })
          : null}
      </HomeContainer>
    </PageWrap>
  )
}

export default HomepageV5
