import React from 'react'
import styled from 'styled-components'
import SmartLink from '../SmartLink'
import tw from 'tailwind.macro'
import cx from 'classnames'
import { screen } from '../../../utils/index'
import { useDateTime } from '../../../hooks/useDateTime'

const Container = styled.div`
  font-family: 'Inter', sans-serif;
  padding-bottom: 25px;
  padding-top: 40px;
  ${tw`
    md:pt-6
    text-white
  `}

  ${screen('md')} {
    padding-top: 22px;
  }
`
const InnerContainer = styled.div`
  max-width: 1360px;
  ${screen('xl')} {
    padding: 18px 40px;
  }
  ${tw`
    my-0
    mx-auto
  `}
`

const Title = styled.h2`
  font-size: 36px;
  line-height: 36px;
  ${tw`
    uppercase
    text-white
  `}

  ${screen('xl')} {
    font-size: 88px;
    line-height: 88px;
  }
`

const Grid = styled.ul`
  list-style-type: none;
  ${tw`
    flex
    m-0
    p-0
    w-full
    flex-wrap
  `}
`
const GridItem = styled.div`
  border: solid 5px transparent;
  ${tw`
    w-full
    relative
`}
`

const GridItemBig = styled.div`
  border: solid 5px transparent;
  ${screen('xl')} {
    min-height: 660px;
  }
  ${tw`
    w-full
    h-full
    relative
  `}
`

const GridItemTop = styled.div`
  height: calc(100vw - 58px);
  line-height: 0;
  ${screen('md')} {
    height: 330px;
  }
  ${tw`
    relative
  `}
`
const GridItemBottom = styled.div`
  padding: 18px;
  ${tw`
    relative
    flex
    flex-col
    justify-between
  `}
  ${screen('xl')} {
    height: 330px;
  }
`
const GridItemBottomBig = styled.div`
  padding: 18px;
  ${tw`
    relative
    flex
    flex-col
    justify-between
  `}
  ${screen('xl')} {
    height: 330px;
    justify-content: flex-end;
  }
`

const GridItemSource = styled.div`
  font-size: 16px;
  line-height: 29px;
  ${tw`
    font-semibold
  `}
`

const GridItemTitle = styled.div`
  font-size: 20px;
  line-height: 20px;
  top: 18px;
  left: 18px;

  ${tw`
    font-semibold
    absolute
  `}

  span {
    ${tw`
      block
    `}
  }
`

const GridItemDescription = styled.div`
  font-size: 30px;
  line-height: 36px;
  overflow: hidden;
  ${tw`
    font-semibold
    xl:h-full
  `}

  a {
    color: currentColor;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: currentColor;
      text-decoration: none;
    }
  }

  span {
    ${tw`
      block
    `}

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`

const GridItemBigBottomGradient = styled.div`
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: -18px;
    left: -18px;
    right: -18px;
    bottom: -18px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
    z-index: -1;
  }
`

const GridItemDescriptionBig = styled.div`
  font-size: 30px;
  line-height: 36px;
  z-index: 0;
  ${tw`
    font-semibold
    xl:h-auto
    relative
  `}


  a {
    color: currentColor;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: currentColor;
      text-decoration: none;
    }
  }

  span {
    ${tw`
      block
    `}

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`

const GridItemImage = styled.img`
  ${screen('md')} {
    height: 330px;
  }
  width: 100%;
  object-fit: cover;
  height: calc(100vw - 58px);
`

const GridItemImageFull = styled.img`
  ${tw`
    w-full
    h-full
    absolute
    top-0
    left-0
    right-0
    bottom-0
  `}
  object-fit: cover;
`

const GridItemBigImage = styled.img`
  object-fit: cover;
  height: calc(100vw - 58px);

  ${tw`
    w-full
    absolute
    left-0
    top-0
    right-0
    bottom-0
    md:h-full
  `}
`

const GreenText = styled.span`
  color: #76f935;
`

const TopContent = styled.div`
  padding-bottom: 30px;
  ${screen('xl')} {
    padding-bottom: 0;
  }

  h2 {
    @media screen and (max-width: 1200px) {
      text-indent: 0 !important;
    }
  }
`

const GridLinkContainer = styled.li`
  a {
    text-decoration: none;
  }
`

const GridLink = ({ link, children, style, className }) => (
  <GridLinkContainer style={style} className={className}>
    <SmartLink to={link || '/'}>{children}</SmartLink>
  </GridLinkContainer>
)

function HomepageTiles({ title_line_one, title_line_two, tiles }) {
  const dateTime = useDateTime()
  if (!tiles || !tiles.length) return null

  return (
    <Container>
      <InnerContainer>
        <TopContent>
          <Title>
            {dateTime.date} — {dateTime.time}
          </Title>
          <Title>
            <GreenText>{title_line_one}</GreenText>
          </Title>
          <Title style={{ textIndent: 300 }}>{title_line_two}</Title>
        </TopContent>
        <Grid>
          {tiles.map((item, index) => {
            return (
              <GridLink
                link={item.link}
                className={cx({
                  'w-full xl:w-1/2': item.big,
                  'w-full md:w-1/2 xl:w-1/4': !item.big,
                })}
                key={`link_${index}`}
              >
                {item.big ? (
                  <GridItemBig>
                    <GridItemBigImage src={item.image} />
                    <GridItemTop
                      style={{
                        color: item.title_color || '#fff',
                      }}
                    >
                      <GridItemTitle
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    </GridItemTop>
                    <GridItemBottomBig
                      style={{
                        color: item.description_and_source_text_color || '#fff',
                      }}
                    >
                      <GridItemBigBottomGradient>
                        <GridItemDescriptionBig
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <GridItemSource>{item.source}</GridItemSource>
                      </GridItemBigBottomGradient>
                    </GridItemBottomBig>
                  </GridItemBig>
                ) : (
                  <GridItem>
                    {item.fullImageBackground && (
                      <GridItemImageFull
                        src={item.image}
                        style={{
                          background: item.fullImageBackground
                            ? item.fullImageTransparentBg
                              ? null
                              : item.background_color
                            : null,
                        }}
                      />
                    )}
                    <GridItemTop
                      style={{
                        color: item.title_color || '#fff',
                      }}
                    >
                      {!item.fullImageBackground && (
                        <GridItemImage src={item.image} />
                      )}
                      <GridItemTitle
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    </GridItemTop>
                    <GridItemBottom
                      style={{
                        background: item.fullImageBackground
                          ? null
                          : item.background_color || '#000',
                        color: item.description_and_source_text_color || '#fff',
                      }}
                    >
                      <GridItemDescription
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      <GridItemSource>{item.source}</GridItemSource>
                    </GridItemBottom>
                  </GridItem>
                )}
              </GridLink>
            )
          })}
        </Grid>
      </InnerContainer>
    </Container>
  )
}

export default HomepageTiles
