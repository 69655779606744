import { useEffect, useState } from 'react'

export const useDateTime = () => {
  const locale = 'en'
  const [today, setDate] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date())
    }, 1 * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const date = `${today.toLocaleDateString(locale, {
    month: 'short',
  })} ${today.getDate()}`

  const hour = today.getHours()
  const minutes = today.getMinutes()
  const seconds = today.getSeconds()

  return {
    date,
    time: `${hour > 9 ? hour : `0${hour}`}:${
      minutes > 9 ? minutes : `0${minutes}`
    }:${seconds > 9 ? seconds : `0${seconds}`}`,
  }
}
