import React from 'react'
import { graphql } from 'gatsby'
import HomepageV5 from '../components/Pages/Home/HomepageV5'
import NoTranslation from '../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

const IndexPage = ({ data, pageContext }) => {
  const page = data.prismicHomepageV5

  if (!page) return <NoTranslation></NoTranslation>

  data = page.data

  const hydratedData = {
    lang: pageContext.locale,
    seo: {
      title: 'Algorand | The Blockchain for FutureFi',
      description:
        'Algorand builds technology that accelerates the convergence between decentralized & traditional finance by enabling the simple creation of next generation financial products, protocols, and exchange of value — Including NFTs, stablecoins, payments & more.',
      keywords: [],
    },
    data,
  }

  return <HomepageV5 {...hydratedData}></HomepageV5>
}

export default withPreview(IndexPage)

export const pageQuery = graphql`
  query HomepageV5ByLocale($locale: String!) {
    prismicHomepageV5(lang: { eq: $locale }) {
      data {
        body {
          items {
            background_color
            description {
              text
            }
            description_and_source_text_color
            icon {
              url
            }
            icon_color {
              url
            }
            image {
              url
            }
            source {
              text
            }
            name {
              text
            }
            marquee_item {
              text
            }
            tile_title {
              text
            }
            title_color
            link {
              text
            }
            project_link {
              text
            }
            big
            background_color
            use_image_as_full_background
            use_transparent_background_color
          }
          primary {
            cta_text {
              text
            }
            description {
              text
            }
            title_line_1 {
              text
            }
            title_line_2 {
              text
            }
            video {
              url
            }
            cta_link {
              text
            }
            text_below_hero {
              text
            }
            text_below_hero_2 {
              text
            }
            title {
              text
            }
            subtitle {
              text
            }
          }
          slice_type
        }
      }
    }
  }
`
